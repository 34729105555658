import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "./DonationForm.css";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import rightArrow from "./images/rightArrow.png";
import loading from "./images/loading.gif";
import FormInput from "./components/FormInput";
import PaymentRequestForm from "./components/PaymentRequestForm";
import zelleQR from "./images/dmf-zelle.png";
import Form from "./components/Form";
const API_BASE_URL = "https://dmf-donation-7b1c31849913.herokuapp.com"; // Replace with your actual API base URL

const apiKey = "pk_live_51MHTSvJob6Z06wXIQKQpBOI9ATfI3fZtTYOPQcGbWdxZ9krIejRxOaM5Xqn5v2oggYb6GVvBUIhnQnkNqH8sbrQF00bBNM7hkX";

const stripePromise = loadStripe(apiKey);

const cardElementOptions = {
  style: {
    base: {
      color: '#131f67', // Change this to your desired color
      fontFamily: ' "sweet-sans-pro", sans-serif',
      textTransform: 'uppercase',
      fontSmoothing: 'antialiased',
      fontWeight: '500',
      fontSize: '14px',
      '::placeholder': {
        color: '#131f67'  // You can also customize the placeholder color
      },
    },
    invalid: {
      color: '#fa755a',  // Color for invalid input
      iconColor: '#fa755a'
    }
  }
};

const DonationForm = () => {
  const ONE_TIME = "one_time";
  const MONTHLY = "monthly";
  const selectedButton = "button-selected";
  const [selectedMonths, setSelectedMonths] = useState(12); // Default to 3 months for monthly donation

  const stripe = useStripe();
  const elements = useElements();
  const [donationType, setDonationType] = useState(ONE_TIME);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(""); // Change this line
  const [lastName, setLastName] = useState("");
  const [amount, setAmount] = useState(0);
  const [prevAmount, setPrevAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [clickedItem, setClickedItem] = useState(1);
  const [dfnInfo, setDfnInfo] = useState("");
  const [dfnCVCPIN, setDfnCVCPIN] = useState("");
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [addedFee, setAddedFee] = useState("");
  const [paymentFinalAmount, setPaymentFinalAmount] = useState(0); // Initialize with a default
  const [feeClicked, setFeeClicked] = useState("false");
  const [ojcCardNumber, setOjcCardNumber] = useState('');
  const [ojcExpirationDate, setOjcExpirationDate] = useState('');
  const [cardNumberPledger, setCardNumberPledger] = useState('');
  const [expirationDatePledger, setExpirationDatePledger] = useState('');
  const [cvv, setCVV] = useState('');
  const [isTdfLoading, setIsTdfLoading] = useState(false);
  const [memoFieldValue, setMemoFieldValue] = useState('');
  const [messageFieldValue, setMessageFieldValue] = useState('');
  const [isPledgerLoading, setIsPledgerLoading] = useState(false);
  const [isOjcLoading, setIsOjcLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Parse the query string
    const queryParams = new URLSearchParams(window.location.search);
    let memo = queryParams.get('memo'); // Get the 'memo' parameter
  
    if (!memo) {
      // If 'memo' parameter does not exist, set memo to "Message"
      memo = "MESSAGE";
    } else {
      // If 'memo' parameter exists, decode it
      memo = decodeURIComponent(memo);
    }
  
    // Use memo to set the memo field value
    setMemoFieldValue(memo);
    setMessageFieldValue(memo);
  }, []); // Empty dependency array means this effect runs once on mount
  


  const handleTdfSubmit = async (e) => {
    e.preventDefault();
    setIsTdfLoading(true); // Start loading

    const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        memo: memoFieldValue,
        amount: amount,
        donor: dfnInfo,
        donorAuthorization: dfnCVCPIN,
        orgName: "DMF",
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/tdf`, data);
      console.log("The donors fund", response.data);
      navigate('/success');
    } catch (error) {
      setIsError(true);
      setErrorMessage('Error submitting the form:', error);
    } finally {
      setIsTdfLoading(false); // Stop loading regardless of the outcome
    }
  };


 // Function to generate a unique invoice number
const generateInvoiceNumber = () => {
  const prefix = 'INV';
  const timestamp = Date.now().toString();
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  return `${prefix}-${timestamp}-${random}`;
};

const handlePledgerSubmit = async (e) => {
  e.preventDefault();

  setIsPledgerLoading(true);

  const invoiceNumber = generateInvoiceNumber();

  const payload = {
    TaxID: "474844275", // Updated to match the TaxID from the curl command
    CharityName: "DMF Cares", // Updated to match the CharityName from the curl command
    Command: "grant:donate",
    Cardnumber: cardNumberPledger,
    CVV: cvv,
    ExpDate: expirationDatePledger,
    Amount: amount,
    Invoice: invoiceNumber,
    Description: "Donate Portal",
    first_name: firstName,
    last_name: lastName,
    email: email,
    memo: memoFieldValue,
    RecurringCount: selectedMonths,
    orgName: "DMF",
  };

  console.log('Sending payload:', payload);

  try {
    const response = await axios.post('https://dmf-donation-7b1c31849913.herokuapp.com/pledger/charge', payload);

    console.log('Response:', response.data);

    if (response.data.Status === "Approved") {
      navigate('/success');
      console.log('Charge successful! Reference number:', response.data.Refnum);
      console.log('Invoice number:', invoiceNumber);
      setIsPledgerLoading(false);
      // Handle successful charge (e.g., show success message to user)
    } else {
      console.error('Charge failed:', response.data.ErrorMessage);
      setIsError(true);
      setErrorMessage(response.data.ErrorMessage);
      setIsPledgerLoading(false);
      // Handle failed charge (e.g., show error message to user)
    }
  } catch (error) {
    console.error('Error submitting the form:', error);
    setIsError(true);
    setErrorMessage('Error submitting the form:', error);
    if (error.response) {
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
      setIsError(true);
    setErrorMessage('Error submitting the form:', error);
    } else if (error.request) {
      console.error('Error request:', error.request);
      setIsError(true);
      setErrorMessage('Error submitting the form:', error);
    } else {
      console.error('Error message:', error.message);
      setIsError(true);
      setErrorMessage('Error submitting the form:', error);
    }
    // Handle network or other errors
  }
};

const formatExpirationDate = (date) => {
  // Remove any non-digit characters and ensure it's exactly 4 digits
  return date.replace(/\D/g, '').padStart(4, '0').slice(0, 4);
};


const ojcHandleSubmit = async (e) => {
  e.preventDefault();

  const invoiceNumber = generateInvoiceNumber();

  const formattedExpDate = formatExpirationDate(ojcExpirationDate);
  
  setIsOjcLoading(true); // Start loading
  // Form data to be posted to the API
  const data = {
    CardNo: ojcCardNumber,
    ExpDate: formattedExpDate,
    OrgId: "SRjLgmeLOyOnVg_JxkwYHw==", // Updated to match the OrgId from the curl example
    Amount: amount,
    ExternalreferenceId: invoiceNumber,
    first_name: firstName,
    last_name: lastName,
    email: email,
    memo: memoFieldValue,
    splitByMonths: selectedMonths,
    orgName: "DMF",
  };

  try {
    const response = await axios.post('https://dmf-donation-7b1c31849913.herokuapp.com/ojc/charge', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log("Response:", response.data);
    
   // Check if the response is a number (successful transaction)
   if (typeof response.data === 'number') {
    console.log('Transaction successful. Transaction ID:', response.data);
    setIsOjcLoading(false); // Start loading
    // Navigate to the success page
    navigate('/success'); // Make sure you have the navigate function from react-router-dom
  } else if (response.data.success) {
    // Navigate to the success page
    setIsOjcLoading(false);
    navigate('/success');
  } else {
    setIsOjcLoading(false);
    setIsError(true);
    setErrorMessage('Error submitting the payment:', response.data.ErrorMessage);
  }

} catch (error) {
  console.error("Error posting data:", error);
  setIsError(true);
 setErrorMessage('Error submitting the form:', error);
  setIsOjcLoading(false);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Error response:", error.response.data);
    setIsError(true);
    setErrorMessage('Error submitting the form:', error.response.data.error);
  } else if (error.request) {
    // The request was made but no response was received
    setIsError(true);
    setErrorMessage('Error submitting the form:', error.response.data.error);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error setting up request:", error.message);
    setIsError(true);
    setErrorMessage('Error submitting the form:', error.response.data.error);
  }
}
};

  // const [expirationError, setExpirationError] = useState("");

  const [coverFees, setCoverFees] = useState(false);

  useEffect(() => {
    if (amount !== prevAmount) {
      // console.log("Input has changed:", prevAmount, "->", amount);
      setPrevAmount(amount);
    }
  }, [amount, prevAmount]);

  useEffect(() => {
    if (clickedItem === 2 && amount !== prevAmount) {
      // If on Google Pay and amount has changed
      setTimeout(() => {
        setClickedItem(8); // Switch to ZELLE
        setTimeout(() => {
          setClickedItem(2); // Switch back to GOOGLE PAY
        }, 11);
      }, 11);
    }
  }, [clickedItem, amount]);

  useEffect(() => {
    const calculateTotalAmount = () => {
      if (!amount) return;

      const taxFee = parseFloat(amount) * 0.025;

      const total = parseFloat(amount) + taxFee;

      setAddedFee(taxFee.toFixed(2));

      setTotalAmount(total.toFixed(2));
    };

    calculateTotalAmount();
  }, [amount]);

  useEffect(() => {
    if (coverFees) {
      setFinalAmount(totalAmount);
      setFeeClicked("true");
    } else {
      setFinalAmount(amount);
      setFeeClicked("false");
    }
  }, [coverFees, amount, totalAmount]);

  useEffect(() => {
    const calculatedAmount = coverFees ? totalAmount : amount;
    setPaymentFinalAmount(calculatedAmount);
  }, [coverFees, totalAmount, amount]);

  useEffect(() => {
    if (isFormSubmitted) {
      // Start a timeout to re-enable the button after 15 seconds
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
        setFormSubmitted(false);
      }, 15000);

      // Clean up the timeout on component unmount or when the form is submitted again
      return () => clearTimeout(timeoutId);
    }
  }, [isFormSubmitted]);

  const handleCoverFeesChange = (e) => {
    setCoverFees(e.target.checked);
  };

  const handleButton = (event) => {
    setDonationType(event.target.value);
    setAmount("0")
    // Reset the selectedMonths to 3 when switching to monthly donation type
    if (event.target.value === MONTHLY) {
      setSelectedMonths(12);
    }
  };

  const handleMonthsButton = (months) => {
    setSelectedMonths(months);
  };

  const renderMonthsButtons = () => {
    if (donationType === MONTHLY) {
      return (
        <div className="monthsButtonContainer">
          <button
            className={
              selectedMonths === 3 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(3)}
          >
            3 Months
          </button>
          <button
            className={
              selectedMonths === 6 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(6)}
          >
            6 Months
          </button>
          <button
            className={
              selectedMonths === 9 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(9)}
          >
            9 Months
          </button>
          <button
            className={
              selectedMonths === 12 ? "selectedMonthButton" : "monthButton"
            }
            onClick={() => handleMonthsButton(12)}
          >
            12 Months
          </button>
        </div>
      );
    }
    return null;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    setButtonDisabled(true);
    setFormSubmitted(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsError(true);
      setErrorMessage(error.message);
      console.error("Error creating payment method:", error);
      return;
    }


    const paymentData = {
      payment_method: paymentMethod.id,
      email,
      first_name: firstName,
      last_name: lastName,
      amount: Math.round(amount * 100), // Convert to cents
      months: selectedMonths,
      orgName: "DMF",
      memo: memoFieldValue,
      message: messageFieldValue,
      Processingfeeamt: addedFee,
      payedFee: feeClicked,
      total: Math.round(paymentFinalAmount * 100),
    };

    if (donationType === MONTHLY) {
      paymentData.duration = selectedMonths;
    }

    const endpoint =
      donationType === ONE_TIME
        ? `${API_BASE_URL}/charge`
        : `${API_BASE_URL}/api/subscribe`;

    try {
      const response = await axios.post(endpoint, paymentData);

      if (response.data.success) {
        navigate("/success");
      } else {
        setIsError(true);
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  const optionsChrome = [
    { id: 1, text: "CREDIT CARD" },
    { id: 2, text: "GOOGLE PAY" },
    { id: 6, text: "ZELLE" },
    { id: 4, text: "DONORS FUND" },
    { id: 5, text: "OJC FUND" },
    { id: 7, text: "PLEDGER" },
    { id: 8, text: "" },
  ];
  const optionsSafari = [
    { id: 1, text: "CREDIT CARD" },
    { id: 2, text: "APPLE PAY" },
    { id: 6, text: "ZELLE" },
    { id: 4, text: "DONORS FUND" },
    { id: 5, text: "OJC FUND" },
    { id: 7, text: "PLEDGER" },
    { id: 8, text: "" },
  ];

  const options = window.chrome ? optionsChrome : optionsSafari;

  const handleItemClick = (itemId) => {
    setClickedItem(itemId);
  };
  // console.log(amount, " amount");
  // console.log(addedFee, " addedFee");
  // console.log(feeClicked, " feeClickedAmount");
  // console.log(totalAmount, " totalAmount");
  // console.log(paymentFinalAmount, " paymentFinalAmount");
  // console.log(memoFieldValue);
  // console.log(messageFieldValue);

  return (
    <form
      name="contact"
      data-netlify={true}
      onSubmit={handleSubmit}
      className="donation-form"
    >
      <input type="hidden" name="form-name" value="donations" />

      <div id="donate"></div>
      <div className="donation-info-cont">
        <h1 className="donation-info-title">Donation Info</h1>

        <div className="inputFieldDonation">
          <input
            type="number"
            min="1"
            placeholder="DONATION AMOUNT"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />

          <div className="buttonContainer">
            <button
              className={`${
                donationType === ONE_TIME ? selectedButton : ""
              } left-button`}
              style={{
                color: donationType === ONE_TIME ? "white" : "#131f67",
              }}
              onClick={handleButton}
              value={ONE_TIME}
            >
              GIVE ONCE
            </button>
            <button
              className={`${
                donationType === MONTHLY ? selectedButton : ""
              } right-button`}
              style={{
                color: donationType === MONTHLY ? "white" : "#131f67",
              }}
              onClick={handleButton}
              value={MONTHLY}
            >
              MONTHLY DONATION
            </button>
          </div>
        </div>
        {renderMonthsButtons()}

        <div className="donationcheckCont">
          <input
            className="donationcheck"
            type="checkbox"
            checked={coverFees}
            onChange={handleCoverFeesChange}
          />
          <h1>
            Please click here if you’d like to cover the
            {clickedItem === 4 ||
            clickedItem === 5 ||
            clickedItem === 6 ||
            clickedItem === 7 ? (
              <span> </span>
            ) : (
              <span> credit card </span>
            )}
            processing fees for your donation. This will change your donation
            total to ${amount === "" ? "" : totalAmount}
          </h1>
        </div>
      </div>

      <div>
        <h1 className="donation-info-title">Payment Info</h1>
      </div>

      <div className="payment-options-cont">
        {options.map(
          (option) =>
            // Check if the text is not an empty string before rendering
            option.text !== "" && (
              <div
                key={option.id}
                onClick={() => handleItemClick(option.id)}
                className="payment-options"
                style={{
                  display: "inline-block",
                  borderRadius: "25px",
                  border:
                    clickedItem === option.id ? "none" : "2px solid #131f67",
                  backgroundColor:
                    clickedItem === option.id ? "#131f67" : "transparent",
                  color: clickedItem === option.id ? "white" : "#131f67",
                  margin: "10px",
                  textAlign: "center",
                  lineHeight: "40px",
                  cursor: "pointer",
                }}
              >
                {option.text}
              </div>
            )
        )}
      </div>

      {clickedItem === 1 ? (
        <div>
          <FormInput
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="FIRST NAME"
          ></FormInput>

          <FormInput
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="LAST NAME"
          ></FormInput>

          <FormInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="EMAIL"
          ></FormInput>

          <FormInput
            type="text"
            value={memoFieldValue}
            onChange={(e) => setMemoFieldValue(e.target.value)}
            required
            placeholder={memoFieldValue}
          ></FormInput>

          <div className="payment-methods">
            {isError && <p style={{color: "red", margin: "20px 0", fontSize: "18px"}}>{errorMessage}</p>}
            <CardElement options={cardElementOptions} className="cc-form"/>
          </div>
        </div>
      ) : null}

      {clickedItem === 2 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {/* <Form /> */}
            <form className="inputForm" action="">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactName"}
                    placeholder={"FIRST NAME"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  >
                    FIRST NAME
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactLastname"}
                    placeholder={"LAST NAME"}
                    type={"text"}
                    className="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactPhone"}
                    placeholder={"PHONE"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    PHONE
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactEmail"}
                    placeholder={"EMAIL"}
                    type={"text"}
                    className="lastName"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="messageFieldForm">
                <label htmlFor="contactTextArea">MESSAGE</label>
                <textarea
                  placeholder={memoFieldValue}
                  id="contactTextArea"
                  cols="1"
                  rows="2"
                  value={memoFieldValue}
                  onChange={(e) => setMemoFieldValue(e.target.value)}
                ></textarea>
              </div>
              {/* <button className="submitButton">SUBMIT</button> */}
            </form>
          </div>
          {/* <h1>Google Pay</h1> */}
          <Elements stripe={stripePromise}>
            <PaymentRequestForm
              amount={paymentFinalAmount}
              total={paymentFinalAmount}
              donationType={donationType}
              email={email}
              firstName={firstName}
              lastName={lastName}
              months={selectedMonths}
              message={memoFieldValue}
              Processingfeeamt={addedFee}
              payedFee={feeClicked}
            />
          </Elements>
        </div>
      ) : null}

      {clickedItem === 8 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {/* <Form /> */}
            <form className="inputForm" action="">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactName"}
                    placeholder={"FIRST NAME"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  >
                    FIRST NAME
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactLastname"}
                    placeholder={"LAST NAME"}
                    type={"text"}
                    className="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <FormInput
                    id={"contactPhone"}
                    placeholder={"PHONE"}
                    type={"text"}
                    borderRight={"2px solid #131f67"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    PHONE
                  </FormInput>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <input
                    id={"contactEmail"}
                    placeholder={"EMAIL"}
                    type={"text"}
                    className="lastName"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="messageFieldForm">
                <label htmlFor="contactTextArea">MESSAGE</label>
                <textarea
                  placeholder={memoFieldValue}
                  id="contactTextArea"
                  cols="1"
                  rows="2"
                  value={memoFieldValue}
                  onChange={(e) => setMemoFieldValue(e.target.value)}
                ></textarea>
              </div>
              {/* <button className="submitButton">SUBMIT</button> */}
            </form>
          </div>
          {/* <h1>Google Pay</h1> */}
          <Elements stripe={stripePromise}>
            <PaymentRequestForm
              amount={paymentFinalAmount}
              donationType={donationType}
              email={email}
              firstName={firstName}
              lastName={lastName}
              months={selectedMonths}
              message={memoFieldValue}
            />
          </Elements>
        </div>
      ) : null}

      {/* {clickedItem === 3 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Form />
          </div>
           <h1>Apple Pay</h1>
          <Elements stripe={stripePromise}>
            <PaymentRequestForm amount={amount} />
          </Elements>
        </div>
      ) : null} */}

{clickedItem === 4 ? (
  <div style={{ marginBottom: "1rem" }}>
    <form onSubmit={handleTdfSubmit}>
      <FormInput
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
        placeholder="FIRST NAME"
      />
      <FormInput
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        required
        placeholder="LAST NAME"
      />

      <FormInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        placeholder="EMAIL"
      />

      <FormInput
        type="text"
        value={memoFieldValue}
        onChange={(e) => setMemoFieldValue(e.target.value)}
        required
        placeholder={memoFieldValue}
      />

      <FormInput
        type="number" // 'any' is not a valid input type, assuming 'text' was intended
        value={dfnInfo}
        onChange={(e) => setDfnInfo(e.target.value)}
        required
        placeholder="TDF CARD NUMBER"
      />

      <FormInput
        type="number"
        value={dfnCVCPIN}
        onChange={(e) => setDfnCVCPIN(e.target.value)}
        required
        placeholder="CVC/PIN"
        className="tdfCVCTurnOffArrows"
      />
      {isError && <p style={{color: "red", margin: "20px 0", fontSize: "18px"}}>{errorMessage}</p>}
      <button className="donate-button" onClick={handleTdfSubmit}>
        {isTdfLoading ? 'Loading...' : 'Submit'}
      </button>
    </form>
  </div>
) : null}

      {clickedItem === 5 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
          </div>
          {/* <h1>OJC Card Number</h1> */}

    <form onSubmit={ojcHandleSubmit}>
      <FormInput
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
        placeholder="FIRST NAME"
      />

      <FormInput
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        required
        placeholder="LAST NAME"
      />

      <FormInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        placeholder="EMAIL"
      />

      <FormInput
        type="text"
        value={memoFieldValue}
        onChange={(e) => setMemoFieldValue(e.target.value)}
        required
        placeholder={memoFieldValue}
      />
      <InputMask
        placeholder="OJC CARD NUMBER"
        required
        value={ojcCardNumber}
        onChange={(e) => setOjcCardNumber(e.target.value)}
        className="inputMask"
        style={{
          backgroundColor: "transparent",
        }}
      />

      <InputMask
        mask="99/99"
        value={ojcExpirationDate}
        onChange={(e) => setOjcExpirationDate(e.target.value)}
        required
        placeholder="MM/YY"
        className="inputMask"
        style={{
          backgroundColor: "transparent",
        }}
      />
      {isError && <p style={{color: "red", margin: "20px 0", fontSize: "18px"}}>{errorMessage}</p>}
      <button type="submit" onClick={ojcHandleSubmit}>
        {isOjcLoading ? 'Loading...' : 'Submit'}
      </button>
    </form>

          {/* {expirationError && <span className="error">{expirationError}</span>} */}
        </div>
      ) : null}

      {clickedItem === 6 ? (
        <div>
          <div className="zelle-cont">
            <h1>
            If you would like to send a Zelle payment to DMF, please add DMF
            as a recipient using the email address, or scan the QR code below.
              <div className="zelle-qr-cont">
                <img width="200px" src={zelleQR} alt="zelleQR" />
              </div>
              <br />
              Please make sure to indicate in the memo the purpose of your
              donation. If you would like a receipt for your donation, please
              email info@dmfcares.org with your mailing and email addresses.
            </h1>
          </div>
        </div>
      ) : null}

      {clickedItem === 7 ? (
        <div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
          </div>
          {/* <h1>PLEDGER</h1> */}
          
          <form onSubmit={handlePledgerSubmit}>
            <FormInput
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              placeholder="FIRST NAME"
            />

            <FormInput
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              placeholder="LAST NAME"
            />

            <FormInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="EMAIL"
            />

            <FormInput
              type="text"
              value={memoFieldValue}
              onChange={(e) => setMemoFieldValue(e.target.value)}
              required
              placeholder={memoFieldValue}
            />
            <FormInput
              type="tel"
              value={cardNumberPledger}
              onChange={(e) => setCardNumberPledger(e.target.value)}
              required
              placeholder="PLEDGER CARD NUMBER"
              pattern="\d*"
              maxLength="16"
            />

            <InputMask
              mask="99/99"
              value={expirationDatePledger}
              onChange={(e) => setExpirationDatePledger(e.target.value)}
              required
              placeholder="MM/YY"
              className="inputMask"
              style={{
                backgroundColor: "transparent",
              }}
            />

            <FormInput
              type="tel"
              value={cvv}
              onChange={(e) => setCVV(e.target.value)}
              required
              placeholder="CVV"
              pattern="\d*"
              maxLength="3"
            />
            {isError && <p style={{color: "red", margin: "20px 0", fontSize: "18px"}}>{errorMessage}</p>}
            <button type="submit" onClick={handlePledgerSubmit}>
              {isPledgerLoading ? 'Loading...' : 'Submit'}
            </button>
        </form>
        </div>
      ) : null}

      {clickedItem !== 6 && clickedItem !== 2 && clickedItem !== 5 && clickedItem !== 7 && clickedItem !== 4 ? (
        <div className="donate-button-cont">
          {isButtonDisabled ? (
            <img
              src={loading}
              alt="loading"
              style={{
                width: "70px",
              }}
            />
          ) : (
            <>
              <button
                type="submit"
                className="donate-button"
                disabled={isButtonDisabled}
                style={{
                  backgroundColor: isButtonDisabled ? "#ff9d89" : "#f6785c",
                }}
              >
                DONATE
              </button>

              <img src={rightArrow} alt="right arrow" className="rightArrow" />
            </>
          )}

          <div
            style={{
              marginLeft: "10px",
              marginTop: "15px",
              color: "#131f67",
              fontSize: amount === "" ? "0.8rem" : "1.3rem",
              textAlign: amount === "" ? "center" : "left",
            }}
            className="finalAmount"
          >
            <span
              style={{
                marginRight: "2px",
              }}
            >
              $
            </span>
            <span className="finalAmount">
              {amount === "" ? "DONATION AMOUNT " : finalAmount}
            </span>
          </div>
        </div>
      ) : null}
    </form>
  );
};

export default DonationForm;