import React, { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";
import DonationForm from "../DonationForm";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import "./DonatePage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";

export default function DonatePage() {
  const [data, setData] = useState([]);

  
  useEffect(() => {
    axios.get('https://dmf-donation-7b1c31849913.herokuapp.com/dmf_campaign')
      .then(response => {
        setData(response.data);
        console.log('this is the response', response.data); // Use response.data instead of data to avoid stale closure
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []); // Empty dependency array ensures this runs only once on mount

  const memoizedData = useMemo(() => data, [data]);

  return (
    <NavbarContextProvider>
      <div className="donatePage">
        <Navbar />
        
        {data.length > 0 && (
          <>
            <Hero imgUrl={data[0].bg_url} />

            <section className="donationFormSection">
              <DonationForm />
            </section>

            <QuoteSection
              backgroundColor={"rgba(255, 157, 137, 0.45)"}
              textColor={"#131f67"}
            >
              {data[0].testimonials}
            </QuoteSection>

            <section
              id="contact"
              style={{
                maxWidth: "1900px",
                margin: "0 auto",
              }}
              className="contactSection"
            >
              <Contact />
            </section>

            {data[0].footer === true ? <Footer /> : null}
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            backgroundColor: "#131f67",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className="copyrightTextDesktop">
            <p> Copyright Davis Memorial Fund. All Rights Reserved. ©2023</p>
          </div>
        </div>
      </div>
    </NavbarContextProvider>
  );
}